<template>
  <div>
    <div class="hero-container" v-if="heroIndex === 2">
      <div class="hero-content">
        <div class="definition">
          <audio id="audio-player" hidden>
            <source src="../assets/reckon.mp3" type="audio/mpeg" />
            <track kind="captions" />
          </audio>
          <!-- <v-row> -->
          <v-item-group>
            <v-container>
              <v-row>
                <v-col xs="12" cols="8">
                  <v-row class="d-inline-block pl-4">
                    <v-row class="align-baseline mb-2">
                      <div class="mr-3 definition-header">reckon</div>
                      <h6 class="definition-verb">verb</h6>
                    </v-row>
                    <v-row class="align-center">
                      <span class="syllables mr-6">reck-on</span>
                      <a
                        class="syllables-audio rounded-pill pa-3"
                        @click="playAudio()"
                        @keydown="handleKeydown($event)"
                      >
                        ˈre-kən
                        <v-icon>mdi-volume-high</v-icon>
                      </a>
                    </v-row>
                    <v-row class="align-center text-h6 text-md-h5 text-lg-h4 mt-6">
                      <span class="syllables mr-6">reckoned; reckoning</span>
                    </v-row>
                    <v-row class="definition-meaning mt-3">
                      <p><strong>1.</strong> To believe something is true.</p>
                    </v-row>
                    <v-row class="definition-meaning left-border">
                      <!-- <v-divider vertical class="definition-divider mr-3"></v-divider> -->
                      <span>We <i>reckon</i> there is no better company.</span>
                    </v-row>
                    <v-row class="definition-meaning mt-6">
                      <p><strong>2.</strong> To make a calculation.</p>
                    </v-row>
                    <v-row class="definition-meaning left-border">
                      <!-- <v-divider vertical class="definition-divider mr-3"></v-divider> -->
                      <span>We <i>reckoned</i> that it was time for a change.</span>
                    </v-row>
                    <!-- <v-row class="mt-9">
                      <p class="text-h5 text-md-h4 text-lg-h3 definition-header">Phrases</p>
                    </v-row>
                    <v-row>
                      <v-divider class="definition-divider"></v-divider>
                    </v-row>
                    <v-row class="text-h6 text-md-h5 text-lg-h4 mt-8">
                      <strong>to be reckoned with</strong>
                    </v-row>
                    <v-row class="text-h6 text-md-h5 text-lg-h4">
                      <p>Something of considerable importance or ability that is not to be ignored or
                        underestimated.</p>
                    </v-row> -->
                    <!-- <v-row class="text-h6 text-md-h5 text-lg-h4 left-border"> -->
                    <!-- <v-divider vertical class="definition-divider mr-3"></v-divider> -->
                    <!-- <span>In our industry, we will become a force <i>to be reckoned with</i>.</span>
                    </v-row> -->
                  </v-row>
                </v-col>
                <v-col cols="4" class="d-none d-sm-block"
                  ><v-img height="300" src="@/assets/reckon_ball_logo.png"
                /></v-col>
              </v-row>
            </v-container>
          </v-item-group>
          <!-- </v-row> -->
        </div>
      </div>
    </div>
    <div class="hero-container-2" v-if="heroIndex === 1">
      <div class="hero-content-2">
        <v-container>
          <v-row>
            <v-col cols="12" class="hero-2-title-container">
              <div><span class="hero-2-title">RECKON IS READY</span></div>
              <div>
                <span class="hero-2-action"
                  ><v-btn elevation="0" color="red">Contact us</v-btn></span
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- <div class="hero-controller">
      <v-btn icon @click="prev">
        <v-icon x-large>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon @click="next">
        <v-icon x-large>mdi-chevron-right</v-icon>
      </v-btn>
    </div> -->
    <div class="content-container gradient-reverse pt-4 pb-10">
      <div class="content">
        <v-container>
          <v-row>
            <v-col cols="12" md="8" class="place-holder-image pa-sm-0 mt-2 mb-6">
              <!-- Insert cool group photo here. -->
              <!-- <v-img src="@/assets/challenges.webp" height="444"></v-img> -->
            </v-col>
            <v-col cols="12" class="pt-0">
              <div class="intro-blurb-title pl-sm-0">
                <span style="color: red">What</span> We Do
              </div>
              <div class="intro-blurb pl-sm-0">
                Our team of expert developers, engineers, and problem-solvers are your modern-day
                heroes. Equipped with cutting-edge tools and unparalleled skills, we swoop in to
                tackle the toughest tech challenges, empowering your business to soar to new
                heights.
                <div class="mt-10"><v-btn outlined @click="navigateTo('capabilities')">See Our Capabilities</v-btn></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="content-container gradient-reverse pt-10">
      <div class="content">
        <v-container>
          <v-row>
            <!-- Text Column -->
            <v-col cols="12" md="4" class="pt-0" order="2" order-md="1">
              <div class="intro-blurb-title pr-md-4 pl-sm-0">
                <span style="color: red">Who</span> We Are
              </div>
              <div class="intro-blurb pr-md-8 pl-sm-0">
                Our team of expert developers, engineers, and problem-solvers are your modern-day
                heroes. Equipped with cutting-edge tools and unparalleled skills, we swoop in to
                tackle the toughest tech challenges, empowering your business to soar to new
                heights.
                <div class="my-10"><v-btn outlined @click="navigateTo('about-us')">Meet the Heroes</v-btn></div>
              </div>
            </v-col>

            <!-- Image Column -->
            <v-col
              cols="12"
              md="8"
              class="place-holder-image pa-sm-0 mt-2 mb-6"
              order="1"
              order-md="2"
            >
              <v-img src="@/assets/reckon_crew.jpeg" @click="showLightbox = true"></v-img>
            </v-col>
          </v-row>
        </v-container>

        <!-- Vue Easy Lightbox -->
        <vue-easy-lightbox
          :visible="showLightbox"
          :imgs="[require('@/assets/reckon_crew.jpeg')]"
          @hide="showLightbox = false"
        />
      </div>
    </div>
    <div class="content-container gradient py-10">
      <div class="content">
        <v-container>
          <v-row>
            <v-col cols="12" md="8" class="place-holder-image pa-sm-0 mt-2 mb-6">
              <!-- Insert cool group photo here. -->
              <v-img src="@/assets/we_want_you.jpeg"></v-img>
            </v-col>
            <v-col cols="12" md="4" class="pt-0">
              <div class="intro-blurb-title pl-md-8 pl-sm-0">
                <span style="color: red">Why</span>
                Join Us?
              </div>
              <div class="intro-blurb pl-md-8 pl-sm-0">
                We've all heard the saying, "Not all Super Heroes wear capes." At Reckon we ask "why
                not?" You supply the talent and super natural abilities and we will provide the
                “cape” as we enable, grow, and embrace everyone's inner superhero, allowing them to
                focus their talents and abilities on tackling the hard problems like fighting
                technical debt, crushing feature creep, etc.
                <div class="my-10"><v-btn outlined @click="navigateTo('benefits')">See What Reckon Offers</v-btn></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="content-container gradient-reverse pt-4 pb-10">
      <div class="content">
        <v-container>
          <v-row>
            <v-col cols="12" md="8" class="place-holder-image pa-sm-0 mt-2 mb-6">
              <!-- Insert cool group photo here. -->
              <!-- <v-img src="@/assets/challenges.webp" height="444"></v-img> -->
            </v-col>
            <v-col cols="12" class="pt-0">
              <div class="intro-blurb-title pl-sm-0">
                Our <span style="color: red">Latest Adventures</span>
              </div>
              <div class="intro-blurb pl-sm-0">
                <v-row>
                  <v-col md="4"
                    ><v-img class="instagram"  src="@/assets/insta-1.png" @click="showLightbox1 = true"></v-img
                  >K1-Speed</v-col>
                  <v-col  md="4"
                    ><v-img class="instagram"  src="@/assets/insta-2.png" @click="showLightbox1 = true"></v-img
                  >Movie Night @ Alamo</v-col>
                  <v-col  md="4"
                    ><v-img class="instagram"  src="@/assets/insta-3.png" @click="showLightbox1 = true"></v-img
                  >Carribean Cruise</v-col>
                  <v-col  md="4"
                    ><v-img class="instagram"  src="@/assets/insta-4.png" @click="showLightbox1 = true"></v-img
                  >Hell's Kitchen - Holiday Party</v-col>
                  <v-col  md="4"
                    ><v-img class="instagram"  src="@/assets/insta-5.png" @click="showLightbox1 = true"></v-img
                  >Desert Drive - Vegas</v-col>
                  <v-col  md="4"
                    ><v-img class="instagram" src="@/assets/insta-6.png" @click="showLightbox1 = true"></v-img
                  >ReInvent</v-col>
                </v-row>
                <div class="mt-10"><v-btn outlined @click="goToInstagram">See More on Instagram</v-btn></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import AppFooter from './Footer.vue';

export default {
  name: 'HomePage',
  components: { AppFooter, VueEasyLightbox },
  data: () => ({
    heroIndex: 2,
    showLightbox: false,
  }),
  methods: {
    playAudio() {
      const audio = document.getElementById('audio-player');
      audio.play();
    },
    handleKeydown(event) {
      if (event.keyCode === 13) {
        this.playAudio();
      }
    },
    prev() {
      // Logic for previous action
      this.heroIndex = this.heroIndex === 1 ? 2 : 1;
    },
    next() {
      // Logic for next action
      this.heroIndex = this.heroIndex === 1 ? 2 : 1;
    },
    navigateTo(destination) {
      this.$router.push(`${destination}`);
    },
    goToInstagram() {
      window.open('https://www.instagram.com/wereckon/', '_blank');
    },
  },
};
</script>
<style scoped>
.text-h3,
.text-h2,
.text-h1 {
  font-family: unset;
}

.left-border {
  border-left: 4px solid red;
  padding-left: 10px;
}

.definition-header {
  font-family: "Playfair Display", serif !important;
  font-size: 2rem !important;
}

.definition-verb {
  font-family: "Playfair Display", serif !important;
  font-size: 1.2rem !important;
}

.definition-meaning {
  font-size: 1.2rem;
}

.definition {
  background: unset;
}

.definition-divider {
  border-color: red !important;
  border-width: 2px;
}

.syllables {
  color: rgb(131, 131, 131);
  font-size: 1rem;
}

.syllables-audio {
  background-color: #333;
  font-size: 1rem;
}

.font-size {
  font-size: 36px;
}

.instagram {
  margin-bottom: 10px;
}

.slogan {
  font-family: "Oswald", serif;
  color: white;
  display: flex;
  width: 100%;
}

.slogan div {
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
}

.v-slide-group {
  background: black;
}

.reckon-card {
  font-family: Oswald, serif;
  font-size: 1.4rem;
  text-align: center;
  width: -webkit-fill-available;
  border-radius: 25px;
  /* border: 2px solid red */
}

.reckon-slide {
  border: 2px solid red;
  /* background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) )
    , url("../assets/action.png"); */
  background-size: cover;
}

.hero-container {
  max-height: 500px;
  display: flex;
  width: auto;
  background-image: url("@/assets/reckon_hero_bg.png");
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* No repeating */
}

.hero-content {
  max-height: 500px;
  min-height: 450px;
  max-width: 1200px;
  width: 100% !important;
  background-color: #2b2b2b4d;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
}
.hero-container-2 {
  max-height: 450px;
  display: flex;
  width: auto;
  background-image: url("@/assets/sky_fight.webp");
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* No repeating */
}

.hero-content-2 {
  max-height: 450px;
  min-height: 450px;
  max-width: 1000px;
  width: 100% !important;
  /* background-color: rgba(14, 31, 38, 0.495); */
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.hero-controller {
  height: 50px;
  border: 1px solid rgb(39, 39, 39);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-2-title-container {
  font-size: 3rem;
  font-weight: 900;
  /* background-color: #525252b4; */
  text-align: end;
}
.hero-2-title {
  font-size: 3rem;
  font-weight: 900;
  background-color: #333333;
  padding: 25px;
}

.gradient {
  background: hsla(0, 0%, 20%, 1);

  background: linear-gradient(
    90deg,
    hsla(0, 0%, 20%, 1) 0%,
    hsla(0, 40%, 29%, 1) 28%,
    hsla(0, 0%, 0%, 1) 93%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(0, 0%, 20%, 1) 0%,
    hsla(0, 40%, 29%, 1) 28%,
    hsla(0, 0%, 0%, 1) 93%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsl(0deg 0.67% 8.9%) 0%,
    hsla(0, 40%, 29%, 1) 28%,
    hsla(0, 0%, 0%, 1) 93%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#343434", endColorstr="#692D2D", GradientType=1 );
}

.gradient-reverse {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(6, 6, 6, 1) 41%,
    rgba(0, 0, 0, 1) 100%
  );
}

.intro-blurb-title {
  color: #fff;
  font-size: 1.9rem;
  /* padding-top: 40px; */
  font-weight: 900;
}

.intro-blurb {
  color: #d5d5d5;
  font-size: 1.4rem;
  padding-top: 20px;
}

.place-holder-image {
  width: 400px;
  /* height: 400px; */
  vertical-align: middle;
  /* border: 1px dotted #ccc; */
}
</style>
