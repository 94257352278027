<template>
  <div :style="getBgStyle" :class="{ 'not-submitted': !submitted, submitted: submitted }">
    <div>
      <v-slide-y-transition>
        <div v-if="submitted" class="after-submitted">
          <div v-if="showContactSoon" class="text-center thank-you-message">
            <div>Thank-you.</div>
            <div>We will be in touch soon!</div>
          </div>
          <div v-if="failed" class="text-center mt-10 thank-you-message">
            <h2 class="text-lg">Oh no!</h2>
            <div class="text-sm">
              An error occurred while submitting your message to us. Please try again soon.
            </div>
          </div>
        </div>
      </v-slide-y-transition>

      <!-- <img class="mx-auto" alt="Vue logo" src="../assets/logo.png" /> -->
      <v-fade-transition>
        <v-form v-if="!submitted" ref="form" v-model="valid" class="mx-auto black-bg content pb-15 mb-15">
          <div style="height: 100px"></div>
          <div class="form">
            <div class="px-5">
              <div v-if="!submitted" class="form-message" style="max-width: 800px">
                <span style="height: 24px">
                  Have questions?
                  <br />
                  We reckon you ask 'em!
                </span>
              </div>
            </div>
            <div class="px-6 form-fields">
              <v-text-field
                v-model="senderName"
                :rules="[(v) => !!v || 'A name is required']"
                label="Your name"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="senderEmail"
                :rules="emailRules"
                label="Your email"
                required
                outlined
              >
              </v-text-field>
              <v-textarea
                v-model="message"
                :rules="[(v) => !!v || 'A message is required']"
                label="Your message"
                required
                outlined
              >
              </v-textarea>
              <v-btn v-if="!loading" :disabled="!valid" raised @click="submit">
                Send a message
              </v-btn>
              <div class="d-flex justify-center">
                <v-progress-circular
                  v-if="loading"
                  :size="50"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-form>
      </v-fade-transition>
    </div>
    <!-- <div style="position: fixed; bottom: 0; width: 100% ;"> -->
      <AppFooter></AppFooter>
    <!-- </div> -->
  </div>
</template>

<script>
import AppFooter from '../components/Footer.vue';

export default {
  name: 'ContactView',
  components: { AppFooter },
  data: () => ({
    valid: true,
    loading: false,
    submitted: false,
    showContactSoon: false,
    failed: false,
    FORM_ENDPOINT: 'https://api.wereckon.com/sendContactEmail',
    emailRules: [
      (v) => !!v || 'An email is required',
      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
    ],

    // form fields
    senderName: '',
    senderEmail: '',
    message: '',
  }),
  methods: {
    submit() {
      this.loading = true;
      setTimeout(() => {
        const body = {
          senderName: this.senderName,
          senderEmail: this.senderEmail,
          message: this.message,
        };
        this.$http
          .post(this.FORM_ENDPOINT, body)
          .then(() => {
            this.failed = false;
            this.submitted = true;
            this.loading = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.submitted = false;
            this.failed = true;
            this.loading = false;
          });
      }, 100);
    },
  },
  computed: {
    getBgStyle() {
      return this.$vuetify.breakpoint.mdAndUp
        ? `background-size: ${window.innerWidth}px ${window.innerHeight}px;`
        : '';
    },
  },
  watch: {
    submitted(val) {
      if (val) {
        setTimeout(() => {
          this.showContactSoon = true;
        }, 1000);
      } else {
        this.showContactSoon = false;
      }
    },
  },
};
</script>
<style scoped>
.desktop-pushdown {
  margin-top: 100px !important;
}

.bigger-bg {
  background-size: cover !important;
}

.theme--dark.v-application {
  background-image: none;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
}

.form-text-field .v-label {
  /* color: rgb(93, 93, 93) !important; */
}

.form-text-field input,
.form-text-field textarea {
  /* color: black !important; */
}
.form-text-field legend {
  /* background-color: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; */
}

.black-bg {
  /* background-color: #000000ad; */
}

.submitted,
.not-submitted {
  min-height: 90vh;
}
.not-submitted {
  background-image: url("/src/assets/Reckon_Cityscape_Skyline_plain.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
}

.submitted {
  background-image: url("/src/assets/Reckon_Cityscape_Skyline_spotlight.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
}

.form-push-down {
  padding-top: 230px;
}

.thank-you-message,
.form-message {
  font-size: 1.7rem;
  background-color: rgba(0, 0, 0, 0.796);
  border-radius: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 20px;
  min-width: 400px;
}

.after-submitted {
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
}

.form-fields {
  margin-top: 30px;
  padding: 24px;
  width: 100%;
  background-color: #000000ad;
}

.form {
  /* position: fixed;
  bottom: 0; */
  width: 100%;
}
</style>
