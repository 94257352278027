<template>
  <div class="footer-container">
    <div class="footer">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <!-- Insert cool group photo here. -->
            <!-- <v-img src="@/assets/challenges.webp"></v-img> -->
            <div class="footer-topic" @click="navigateTo('/')"  @keydown="navigateTo('/')">Home</div>
            <div class="footer-topic" @click="navigateTo('about-us')" @keydown="navigateTo('about-us')">Meet the Heroes</div>
            <div class="footer-topic" @click="navigateTo('benefits')" @keydown="navigateTo('benefits')">Top Tier Benefits</div>
          </v-col>
          <v-col cols="12" md="4">
            <!-- Insert cool group photo here. -->
            <!-- <v-img src="@/assets/challenges.webp"></v-img> -->
            <div class="footer-topic" @click="navigateTo('capabilities')" @keydown="navigateTo('capabilities')">Capabilities</div>
            <div class="footer-topic" @click="navigateTo('beliefs')" @keydown="navigateTo('beliefs')">Core Beliefs</div>
          </v-col>
          <v-col cols="12" md="4">
            <!-- Insert cool group photo here. -->
            <!-- <v-img src="@/assets/challenges.webp"></v-img> -->
            <div class="footer-section-title font-weight-bold">Connect With US</div>
            <div class="footer-topic">
              <button @click="goToLinkedIn">
                <v-icon x-large>mdi-linkedin</v-icon>
              </button>
              <button @click="goToInstagram">
                <v-icon x-large class="ml-3">mdi-instagram</v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({}),
  methods: {
    goToLinkedIn() {
      window.open('https://www.linkedin.com/company/reckonllc/', '_blank');
    },
    goToInstagram() {
      window.open('https://www.instagram.com/wereckon/', '_blank');
    },
    navigateTo(destination) {
      this.$router.push(`${destination}`);
    },
  },
};
</script>

<style>
.footer-container {
  /* max-height: 350px; */
  display: flex;
  width: auto;
  background-color: #171717;
  padding: 15px;
}

.footer {
  /* max-height: 350px; */
  max-width: 1000px;
  width: 100% !important;
  /* background-color: rgba(0, 90, 128, 0.464); */
  margin-left: auto;
  margin-right: auto;
}

.footer-section-title {
  font-size: 1.3rem;
}

.footer-topic {
  margin-top: 10px;
  color: #ccc;
  cursor: pointer;
}
</style>
