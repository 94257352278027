<template>
  <div class="header-container">
    <div class="header">
      <v-container>
          <v-row>
            <v-col cols="12" class="header-title-container px-0">
              <div><span class="header-title"><slot></slot></span></div>
              <!-- <div><span class="hero-2-action"><v-btn elevation="0" color="red">Contact us</v-btn></span></div> -->
            </v-col>
          </v-row>
        </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  data: () => ({}),
};
</script>

<style scoped>
.header-container {
  max-height: 450px;
  display: flex;
  width: auto;
  background-image: url("@/assets/reckon_hero_bg.png");
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* No repeating */
  margin-bottom: 30px;

}

.header {
  max-height: 450px;
  min-height: 300px;
  max-width: 1000px;
  width: 100% !important;
  /* background-color: rgba(14, 31, 38, 0.495); */
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  display:flex;
  flex-direction: column;
  justify-content:end;
}

.header-title-container {
  font-weight:900;
  /* background-color: #525252b4; */
  text-align: center;
  border-bottom: solid 5px red;
}

.header-title {
  font-size: 2.5rem;
  font-weight:900;
  /* background-color: #333333; */
  padding: 10px;
  text-align: center;
}
</style>
