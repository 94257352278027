<template>
  <div>
    <AppHeader>Our Beliefs</AppHeader>
    <div class="mb-6 content">
      <div class="text-h6 text-md-h4 mx-6 text-section text-center">
        At Reckon, we believe that inspiration can come from non-traditional avenues. Our company
        takes its cues from the world around us such as the greatest leaders, teachers, coaches and
        artist that pop culture has provided.
      </div>
      <div class="ml-6 my-5 text-h4 text-md-h3 we-reckon">We Reckon...</div>
      <v-expansion-panels popout multiple :value="[0, 1, 2, 3, 4, 5]">
        <div
          v-for="value in coreValues"
          :key="value.InstanceTypecoreValue"
          class="my-5"
          style="width: 100%"
        >
          <core-value
            :mainBelief="value.mainBelief"
            :coreValue="value.coreValue"
            :person="value.person"
            :quote="value.quote"
          ></core-value>
        </div>
      </v-expansion-panels>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>
<script>
import CoreValue from '@/components/CoreValue.vue';
import AppFooter from '../components/Footer.vue';
import AppHeader from '../components/Header.vue';

export default {
  name: 'BeliefsView',
  components: { CoreValue, AppFooter, AppHeader },
  data: () => ({
    coreValues: [
      {
        mainBelief: '...determination and drive can overcome problems and obstacles.',
        coreValue:
          'At Reckon, we believe that when we set our minds to do something we will accomplish our goal, whether it is to build a one-of-kind revolutionary company from the ground up, solve one of our customer’s most challenging technical problems, or to use the Force to lift an X-wing from a swamp on Degobah.',
        quote: '“Do. Or do not. There is no try.”',
        person: 'Master Yoda',
      },
      {
        mainBelief: '...in being bold.',
        coreValue:
          'At Reckon, we believe in going boldly where no person or company has gone before, a place where transparency, agency, and culture reign supreme and everyone has the ability to “make it so.”',
        quote: '“Things are only impossible until they are not.”',
        person: 'Jean-Luc Picard',
      },
      {
        mainBelief: '...embracing being different.',
        coreValue:
          'At Reckon, we believe that we did not build a company to be like any other. We embrace differences to give our company a competitive advantage, promote innovation and employee engagement, and build customer loyalty.',
        quote:
          '“We can always kind of be average and do what’s normal. I’m not in this to do what’s normal.”',
        person: 'Kobe Bryant',
      },
      {
        mainBelief: '...having a growth mindset.',
        coreValue:
          'At Reckon, we believe that having a growth mindset permeates everything we do as a company.  We believe in dedication and hard work, brains and talent are just the starting point. Our outlook creates a love of learning and a resilience that is essential for great accomplishment. We embrace challenges and seek out inspiration in others’ success. Through this perspective, they can’t knock the hustle.',
        quote: '“Nobody built like you, you design yourself.”',
        person: 'Jay-Z',
      },
      {
        mainBelief: '...diversity makes us stronger.',
        coreValue:
          'At Reckon, we believe that diversity makes us stronger. Diverse teams are better at decision making because they consider a wider range of perspectives and ideas. A diverse and inclusive workplace improves employee satisfaction and morale. Employees feel valued and respected when they work in an environment that respects and appreciates their differences.',
        quote:
          '"The idea was to bring together a group of remarkable people to see if they could become something more. To see if they could work together when we needed them to, to fight the battles that we never could.”',
        person: 'Nick Fury',
      },
      {
        mainBelief: '...in seeking balance.',
        coreValue:
          'At Reckon, we enable a healthy work-life balance so our employees are productive and engaged at work. By prioritizing work-life balance, we are more likely to retain employees and attract new talent. We at Reckon are an engaged and motivated “All Valley champion” workforce.',
        quote:
          '“Better learn balance. Balance is key. Balance good, karate good. Everything good. Balance bad, better pack up, go home. Understand?”',
        person: 'Mr. Miyagi',
      },
      {
        mainBelief: '...celebrating success along the way.',
        quote: '“Damn right I live the life I live, cause I went from negative to positive”',
        person: 'Notorious BIG',
      },
    ],
  }),
};
</script>
<style>
.we-reckon {
  font-weight: 200;
}
</style>
