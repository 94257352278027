<template>
  <div class="mx-6 mx-md-15">
    <v-expansion-panel>
      <v-expansion-panel-header class="value-header">
        <v-row class="value-block">
          <v-col cols="12" md="6" class="pl-8 d-flex align-center text-h6 text-md-h5">
            <div>{{mainBelief}}</div>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center quote-col">
            <blockquote class="mx-10 font-italic block-quote text-subtitle-1 text-md-h5">
              <div class="quote">
                {{quote}}
              </div>
              <div>
                - {{ person }}
              </div>
            </blockquote>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="pt-4 value-text">{{ coreValue }}</div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-dialog
      v-model="dialog"
      width="50%"
    >
      <v-card append-icon="mdi-close">
        <template v-slot:append>
          <v-btn icon="mdi-close" variant="text" @click="dialog = false"></v-btn>
        </template>
        <v-btn icon="close" variant="text" @click="dialog = false"></v-btn>
        <v-btn icon="mdiClose" variant="text" @click="dialog = false"></v-btn>
        <v-btn icon="mdi-close" variant="text" @click="dialog = false"></v-btn>
        <v-card-text>
          {{ coreValue }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = false">Close Dialog</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    mainBelief: String,
    coreValue: String,
    quote: String,
    person: String,
  },
};
</script>
<style scoped>

.value-text {
  font-size: 1.2rem;
}

.value-header {
 background-color: #333333a6;
}

.value-block {
 border-radius: 35px;
 border-top-left-radius: 0;
 border-bottom-left-radius: 0;
 border-left: 5px solid red;
 /* padding: 25px; */
 font-size: xx-large;
}

.quote {
  font-weight: 900;
}

.quote-col {
  color: black;
  /* background-color: whitesmoke; */
  position: relative;
  z-index: 1;
  /* background:
    linear-gradient(to top left ,purple 50%,#0000 51%) top,
    linear-gradient(purple 0 0) center,
    linear-gradient(to bottom right ,purple 50%,#0000 51%) bottom; */
}

.quote-col::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: calc(100% + 55px);
  height: calc(100% + 25px);
  background-color: rgb(225, 225, 225);
  clip-path: polygon(8% 3%, 98% 3%, 93% 77%, 2% 97%);
}

.quote-col::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  width: calc(100% + 55px);
  height: calc(100% + 25px);
  background-color: #b59c3f;
  clip-path: polygon(7% 0, 100% 0, 95% 80%, 0 100%);
}
</style>
