<template>
  <div>
    <AppHeader>Benefits</AppHeader>
    <div class="mb-6 content">
      <div class="mx-6 mx-md-15 text-h5 mb-8">
        <div>
          <div class="text-section">
            Reckon offers a comprehensive set of benefits to ensure the well-being, growth, and satisfaction of our employees. Our benefits include:
          </div>
          <div style="margin-top: 50px" class="caps">
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                100% Employer-Paid Medical / Dental / Vision Benefits
              </div>
              <ul>
                <li>Anthem Platinum PPO ($0 deductible)</li>
                <li>Principal Dental</li>
                <li>Principal Vision</li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                Employer-Sponsored 401(k) Plan (Vanguard)
              </div>
              <ul>
                <li>12% Employer Contribution</li>
                <li>Immediately vesting at 100%</li>
                <li>Potential for up to 3% profit sharing</li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                Time Off
              </div>
              <ul>
                <li>All Federal Holidays Paid (11 for 2024)</li>
                <li>Flexible PTO plans to suit your needs</li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                Employee Growth
              </div>
              <ul>
                <li>Budget for Training or Trade Conference attendance, travel, and lodging</li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                Equity
              </div>
              <ul>
                <li>All employees are eligible to participate in the Reckon Equity Plan</li>
              </ul>
            </div>
            <div>
              <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">
                Events
              </div>
              <ul>
                <li>Happy Hours and weekly AMAs</li>
                <li>Holiday Party</li>
                <li>Yearly Corporate Retreat (TBD)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppFooter from '../components/Footer.vue';
import AppHeader from '../components/Header.vue';

export default {
  name: 'BenefitsPage',
  components: { AppFooter, AppHeader },
};
</script>

<style>
div.caps > div > div {
  margin: 60px 0 20px 0;
  font-size: larger;
  font-weight: bold;
}
ul {
  margin-top: 10px;
  margin-left: 20px;
  list-style-type: disc;
}
</style>
