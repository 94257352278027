<!-- eslint-disable max-len -->
<template>
  <v-app id="reckon-app">
    <v-app-bar
      app
      color="black"
      dark
    >
      <div class="align-center">
        <router-link :to="{path: '/'}">
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            min-width="100"
            src="./assets/reckon_logo.png"
            width="150"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="d-none d-md-block">
        <v-btn
          :to="{name: 'contact'}"
          text
          style="font-size: 1.5rem;"
        >
          <span  style="font-family: Oswald, serif;">Contact Us</span>
        </v-btn>
      </div>
      <v-icon @click="openNavMenu" class="mt-2 ml-3">mdi-menu</v-icon>
    </v-app-bar>
    <v-main id="main-container">

      <v-overlay :value="overlay" :opacity=".8"></v-overlay>
    <div class="main-container">

      <router-view/>
      </div>
    </v-main>
    <div transition="scroll-x-reverse-transition" v-if="navMenu" class="nav-menu" v-click-outside="closeNavMenu">
      <div class="link px-0" @click="closeNavMenu(); navigateTo('/')" :class="{ 'current-route': isCurrentRoute('home')}" @keydown="closeNavMenu">
        <div class="py-0 content">
          <div class="section-title">
            HOME
          </div>
          <div class="subtitle">
            BACK TO THE START
          </div>
        </div>
      </div>
      <div class="link px-0" @click="closeNavMenu(); navigateTo('beliefs')" :class="{ 'current-route': isCurrentRoute('beliefs')}" @keydown="closeNavMenu">
      <!-- <div class="link px-0" :class="{ 'current-route': isCurrentRoute('beliefs')}" @keydown="closeNavMenu"> -->
        <div class="py-0 content">
          <div class="section-title">
            WE RECKON
          </div>
          <div class="subtitle">
            CORE BELIEFS
          </div>
        </div>
      </div>
      <div class="link px-0" @click="closeNavMenu(); navigateTo('beginnings')" :class="{ 'current-route': isCurrentRoute('beginnings')}" @keydown="closeNavMenu">
      <!-- <div class="link px-0" :class="{ 'current-route': isCurrentRoute('beginnings')}" @keydown="closeNavMenu"> -->
        <div class="py-0 content">
          <div class="section-title">
            THE RECKONING
          </div>
          <div class="subtitle">
            <!-- HOW IT STARTED -->
            ORIGIN STORY
          </div>
        </div>
      </div>
      <div class="link px-0" @click="closeNavMenu(); navigateTo('about-us')"  :class="{ 'current-route': isCurrentRoute('about-us')}" @keydown="closeNavMenu">
      <!-- <div class="link px-0" :class="{ 'current-route': isCurrentRoute('about-us')}" @keydown="closeNavMenu"> -->
        <div class="py-0 content">
          <div class="section-title">
            THE RECKON CREW
          </div>
          <div class="subtitle">
            <!-- MEET THE TEAM -->
            OUR TEAM
          </div>
        </div>
      </div>
     <div class="link px-0" @click="closeNavMenu(); navigateTo('capabilities')" :class="{ 'current-route': isCurrentRoute('capabilities')}" @keydown="closeNavMenu">
      <!-- <div class="link px-0" :class="{ 'current-route': isCurrentRoute('capabilities')}" @keydown="closeNavMenu"> -->
        <div class="py-0 content">
          <div class="section-title">
            <!-- CAPABILITIES -->
            A FORCE TO RECKON
          </div>
          <div class="subtitle">
            <!-- MEET THE TEAM -->
            OUR SUPER POWERS (CAPABILITIES)
          </div>
        </div>
      </div>
      <div class="link px-0" @click="closeNavMenu(); navigateTo('benefits')" :class="{ 'current-route': isCurrentRoute('benefits')}" @keydown="closeNavMenu">
      <!-- <div class="link px-0" :class="{ 'current-route': isCurrentRoute('capabilities')}" @keydown="closeNavMenu"> -->
        <div class="py-0 content">
          <div class="section-title">
            <!-- BENEFITS -->
            RECKON IT ALL
          </div>
          <div class="subtitle">
            <!-- MEET THE TEAM -->
            OUR BENEFITS
          </div>
        </div>
      </div>
      <div class="link px-0" @click="closeNavMenu(); navigateTo('contact')" :class="{ 'current-route': isCurrentRoute('contact')}" @keydown="closeNavMenu">
        <div class="py-0 content">
          <div class="section-title">
            CONTACT US
          </div>
          <div class="subtitle">
            OUR BAT SIGNAL
          </div>
        </div>
      </div>
    </div>
    <!-- <v-navigation-drawer right temporary absolute v-model="navMenu" class="nav-menu px-6">

    </v-navigation-drawer> -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    navMenu: null,
    overlay: false,
  }),
  methods: {
    closeNavMenu() {
      console.log('closeNavMenu');
      this.navMenu = false;
      this.overlay = false;
    },
    openNavMenu() {
      this.navMenu = !this.navMenu;
      this.overlay = true;
      document.getElementById('reckon-app').style.overflow = 'hidden';
    },
    navigateTo(destination) {
      this.$router.push(`${destination}`);
    },
    isCurrentRoute(routeName) {
      return this.$route.name === routeName;
    },
  },
  computed: {
  },
};
</script>

<style lang="scss">
@import './styles/variables.scss';

.v-application {
  font-family: $body-font-family !important;
  .block-quote {
    font-family: "Comic Neue", cursive !important;
  }
}

.v-main {
  z-index: 0;
  overflow: hidden;
}

.theme--dark.v-application {
  /* background-image: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) )
    , url("./assets/comic_bg.png"); */
  background-color: black;
  background-size: cover;

}
.nav-menu {
  color: white;
  position:fixed;
  right:0;
  z-index:5;
  background-color: black;
  height:100%;
  cursor: pointer;
}

.section-title {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.8rem;
  font-family: "Oswald", serif !important;
  padding-top: 24px;
}

.subtitle {
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: .8rem;
  color: rgb(192, 30, 30) !important;
  font-family: "Oswald", serif;
}

.v-application a {
  color: white !important;
}

.v-list-item--active {
  background: unset;
}

.text-section {
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #333;
}

.link {
  border-bottom: 1px solid #222;

}

.link:hover {
  background-color: #111;
}

.link:active {
  background-color: #111;
}

.current-route {
  background-color: #222;
  border-left: 5px solid red;
}

.main-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    min-height: 100vh;
}
</style>
